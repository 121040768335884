@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

.terminal-content {
  padding: 10px;
  font-family: 'Anonymous Pro', monospace;
  color: #00ff00;
  background-color: #000000; /* Remove transparency */
  height: 100%;
  overflow-y: auto;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: pre-wrap;
  text-align: left; /* Align text to the left */
}

.input-line {
  display: flex;
  align-items: center;
}

.prompt-symbol {
  color: #00ff00;
}

input[type="text"] {
  background: none;
  border: none;
  color: #00ff00;
  font-family: 'Anonymous Pro', monospace;
  outline: none;
  flex-grow: 1;
  z-index: 1; /* Ensure input is on top */
  font-size: 16px; /* Prevent zoom on mobile devices */
}

.terminal-wrapper {
  width: 80%;
  height: 70%;
  margin: 0 auto;
  padding: 0;
  background-color: #000000; /* Remove transparency */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: auto; /* Allow content to be scrollable */
}

.terminal-header {
  background-color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff5f56;
}

.button.minimize {
  background-color: #ffbd2e;
}

.button.maximize {
  background-color: #27c93f;
}

.title {
  color: #fff;
  font-size: 0.9rem;
  flex-grow: 1;
  text-align: center;
  padding-right: 2rem;
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

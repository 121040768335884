@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

.Start {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  font-family: 'Anonymous Pro', monospace; /* Hacker-type font */
  overflow: auto; /* Make content scrollable */
  overflow-x: auto; /* Allow horizontal scrolling */
}

.hacker-font {
  font-family: 'Anonymous Pro', monospace;
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.terminal-wrapper {
  width: 80%;
  height: 70%;
  margin: 0 auto;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow: auto; /* Make content scrollable */
}

.terminal-header {
  background-color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.buttons {
  display: flex;
  gap: 0.5rem;
}

.button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff5f56;
}

.button.minimize {
  background-color: #ffbd2e;
}

.button.maximize {
  background-color: #27c93f;
}

/* @media (max-width: 768px) {
  .button, .button.minimize, .button.maximize {
    display: none;
  }
} */

.title {
  color: #fff;
  font-size: 0.9rem;
  flex-grow: 1;
  text-align: center;
  padding-right: 2rem;
}

@media (max-width: 768px) {
  .title {
    text-align: center;
    padding-right: 0;
  }
}

.terminal-content {
  flex-grow: 1;
  overflow-x: auto; /* Allow horizontal scrolling on mobile */
  white-space: nowrap; /* Prevent content from wrapping */
  min-width: 100%; /* Ensure content can expand beyond container */
}

@media (max-width: 768px) {
  .terminal-wrapper {
    width: 95%;
    height: 85%;
    overflow-x: auto; /* Ensure the wrapper itself can scroll horizontally */
  }
}

